<template>
  <div class="w-full h-full pt-5">
    <div class="flex">
      <back-button
        label="Back"
        @onClick="$router.back()"
        variant="secondary"
        class="mr-4"
      />
      <div
        class="mt-4"
        style="border-left: 1px solid #878e99; height: 1.5rem"
      />
      <h1 class="text-xl text-left font-extrabold ml-4 mt-3 w-auto">
        <span v-if="payrunData.type !== 'off-cycle' && $route.query.month">
          {{
            `${$DATEFORMAT(
              new Date($route.query.year, $route.query.month - 1, 1),
              "LLLL"
            )}
              ${$route.query.year}`
          }}
          Pay Cycle
        </span>
        <span v-else>
          Off-Cycle Pay
        </span>
      </h1>
      <div v-if="payrunData.type === 'off-cycle'" class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbsOffCycle" />
      </div>
      <div v-else class="w-7/12 mt-4 ml-8">
        <Breadcrumb :items="breadcrumbs" />
      </div>
    </div>

    <div style="height: 100%" v-if="loading">
      <loader size="xxs" :loader-image="false" />
    </div>
    <div v-else>
      <div class="px-3">
        <card class="p-5 mt-6">
          <div class="w-full mb-4 flex flex-col gap-5 border border-dashed border-romanSilver rounded p-3">
            <div class="w-full flex justify-between items-center">
              <TrendToolTip :is-trend-tool-tip="isTrendToolTip"
                @on-enter=" isTrendToolTip = true"
                @on-leave=" isTrendToolTip = false"
              />
              <p @click="isTrend = !isTrend"
                class="w-1/3 cursor-pointer text-right text-blueCrayola font-semibold text-sm"
              >
                  {{isTrend ? `Collapse Trend` : `Expand Trend`}}
              </p>
            </div>
            <div v-if="isTrend" class="gridContainer">
              <TrendState
                type="Last month"
                :amount="payrunTrend ? payrunTrend.previousPayrun.grossPay : 0"
                :net-amount="payrunTrend ? payrunTrend.previousPayrun.netPay : 0"
                :emp-count="payrunTrend ? payrunTrend.previousPayrun.empCount : 0"
                icon-name="blue-gross-icon"
              />

              <TrendState
                type="Current month"
                :amount="payrunTrend ? payrunTrend.currentPayrun.grossPay : 0"
                :net-amount="payrunTrend ? payrunTrend.currentPayrun.netPay : 0"
                :emp-count="payrunTrend ? payrunTrend.currentPayrun.empCount : 0"
                icon-name="yellow-gross-icon"
              />

              <TrendState
                type="Variance"
                :amount="payrunTrend ? payrunTrend.variance.grossPay : 0"
                :net-amount="payrunTrend ? payrunTrend.variance.netPay : 0"
                :emp-count="payrunTrend ? payrunTrend.variance.empCount : 0"
                icon-name="green-gross-icon"
                :trend="growthTrend"
              />
            </div>
          </div>
          <div class="w-full h-auto p-3 rounded" style="border: 1px dashed #878E99">
            <p class="font-bold text-lg mb-4 ">Pay Run Information</p>
            <div class="w-full flex flex-wrap justify-start items-center gap-2">
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  Pay run Type
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase flex flex-col justify-start">
                  <span>{{ payrunData.payType }}</span>
                  <span v-if="payrunData.type === 'off-cycle'" class="text-blueCrayola text-10">
                    {{ payrunData.type }}
                  </span>
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                    Payment frequency:
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{
                    payrunData.payFrequency === "twice_monthly"
                      ? "Twice Monthly"
                      : payrunData.payFrequency === "weekly"
                      ? "Four Times Monthly"
                      : payrunData.payFrequency
                  }}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  Payment Schedule:
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.paySplitPosition ? `${$getOrdinal(payrunData.paySplitPosition)} Payment` : "NA" }}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  pay date
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{
                    `${$DATEFORMAT(
                      new Date(payrunData.createdAt),
                      "MMMM dd, yyyy"
                    )}`
                  }}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  initiated by
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.initiatedBy ? payrunData.initiatedBy : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  approved by
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.approval ? payrunData.approval : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  paid by
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.paidBy ? payrunData.paidBy : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  pay reference #
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  CL16282637272
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  total employees
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ selectedEmployees }} of {{ employeeTotalCount }}
                </h6>
              </card>
              <card class="p-3 w-204">
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  success rate
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                  {{ payrunData.successRate ? payrunData.successRate : "---"}}
                </h6>
              </card>
              <card class="p-3 w-204 relative">
                <div @click="isTotalGross = true"
                  class="w-auto h-auto absolute top-3 right-3 cursor-pointer"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                </div>
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  total Gross pay
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ convertToCurrency(employeeGrossPay) }}
                </h6>
              </card>
              <card class="p-3 w-204 relative">
                <div @click="isTotalGross = true"
                  class="w-auto h-auto absolute top-3 right-3 cursor-pointer"
                >
                  <icon
                    icon-name="icon-eye"
                    class-name="text-blueCrayola"
                    size="xs"
                  />
                </div>
                <h6 class=" uppercase mb-2 font-black text-xs text-darkPurple">
                  total net pay
                </h6>
                <h6 class="font-semibold text-sm text-jet uppercase">
                    {{ convertToCurrency(totalNetPay) }}
                </h6>
              </card>
            </div>
          </div>
          <div class="w-full flex justify-end my-5">
            <div class="flex my-auto">
              <Button
                class="text-white buttons flex mr-5"
                :background="appearance.buttonColor || defaultBTNColor"
                @click="save('Under Review')"
                :disabled="disableBtn"
              >
                {{ payrunData.type !== 'off-cycle' ? 'Submit Pay Run' : 'Process' }}
              </Button>
              <Button
                class="bg-white ml-4 text-dynamicBackBtn border border-solid border-dynamicBackBtn"
                style-name="width:inherit;"
                options
                :option-list="['Save as Draft', 'Cancel']"
                @click="buttonOptionSelected($event)"
              >
                Select an Option
              </Button>
            </div>
          </div>
        </card>
      </div>
      <div>
        <div class="px-3"></div>
        <div class="px-3">
          <card class="p-1 mt-6 mb-8">
            <sTable
              :headers="headers"
              :items="tableData"
              style="width: 100%"
              class="w-full"
              :has-checkbox="true"
              aria-label="payRun table"
              :has-number="false"
              :loading="tableLoading"
              @rowSelected="handleRowSelected($event)"
              selection-key="userId"
              :pagination-list="metaData"
              @page="handlePage($event)"
              page-sync
              @itemsPerPage="handleItemsPerPage($event)"
            >
              <template v-slot:item="{ item }">
                <div v-if="item.photo" class="flex items-center gap-3">
                  <div v-if="isPresent" class="rounded-full sideDot1"></div>
                  <div v-else class="rounded-full sideDot2"></div>
                  <img
                    class=""
                    :src="item.data.photo"
                    v-if="item.data.photo"
                    alt="photo"
                    style="height: 30px; width: 30px; border-radius: 5px"
                  />
                  <div
                    style="height: 35px; width: 35px; border-radius: 5px"
                    class="text-blueCrayola border text-center font-semibold pt-2"
                    v-else
                  >
                    {{ $getInitials(`${item.data.fname} ${item.data.lname}`) }}
                  </div>
                </div>
                <div v-else-if="item.fname">
                  <span class="tw-font-bold" style="color: #525252"
                    >{{ item.data.fname }} {{ item.data.lname }}</span
                  >
                  <p class="uppercase text-xs text-romanSilver">
                    {{
                      item.data.orgFunction ? item.data.orgFunction.name : ""
                    }}
                  </p>
                </div>
                <span v-else-if="item.regularEarnings" class="tw-text-center">
                  {{
                    !item.data.regularEarnings
                      ? "Nil"
                      : convertToCurrency(item.data.regularEarnings)
                  }}
                </span>
                <span v-else-if="item.additions" class="flex text-center">
                  <Menu
                    right
                    top="-300"
                    margin="46"
                    class="p-0"
                    v-if="item.data.additions"
                  >
                    <template v-slot:title>
                      <div class="underline text-blueCrayola">
                        {{ convertToCurrency(item.data.additions) }}
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(additions, index) in item.data
                          .monthlyAdditionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ additions.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(additions.amount) }}
                        </p>
                      </div>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span>
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 mt-0 self-center"
                      size="xms"
                      @click.native="handleClick('addition', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.additions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('addition', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
                <span v-else-if="item.deductions" class="flex text-center">
                  <Menu
                    right
                    top="-300"
                    margin="46"
                    class="p-0"
                    v-if="item.data.deductions"
                  >
                    <template v-slot:title>
                      <div class="underline text-blueCrayola">
                        {{ convertToCurrency(item.data.deductions) }}
                      </div>
                    </template>
                    <div style="width: 250px; padding: 10px 5px">
                      <div
                        class="flex flex-col py-2 px-3"
                        v-for="(deduction, index) in item.data
                          .monthlyDeductionsPayItems"
                        :key="index"
                      >
                        <p class="pt-1 flex flex-grow text-sm font-semibold">
                          {{ deduction.name }}
                        </p>
                        <p class="pt-1 flex text-xs text-romanSilver">
                          {{ convertToCurrency(deduction.amount) }}
                        </p>
                      </div>
                    </div>
                  </Menu>
                  <span v-else>
                    {{ convertToCurrency(0) }}
                  </span>
                  <span>
                    <Icon
                      class-name="text-flame cursor-pointer ml-4 self-center"
                      size="xms"
                      @click.native="handleClick('deduction', item.data.userId)"
                      icon-name="addition"
                    />
                  </span>
                  <span v-if="item.data.deductions > 1">
                    <Icon
                      class-name="text-blueCrayola cursor-pointer ml-2 mt-0 self-center"
                      size="xms"
                      @click.native="handleEdit('deduction', item.data.userId)"
                      icon-name="edit"
                    />
                  </span>
                </span>
                <span v-else-if="item.paye" class="tw-text-center">
                  {{ item.data.paye ? convertToCurrency(item.data.paye) : 0 }}
                </span>
                <span v-else-if="item.employeePension" class="tw-text-center">
                  {{
                    item.data.employeePension
                      ? convertToCurrency(item.data.employeePension)
                      : 0
                  }}
                </span>
                <span v-else-if="item.nhfAmount" class="tw-text-center">
                  {{
                    item.data.nhfAmount
                      ? convertToCurrency(item.data.nhfAmount)
                      : 0
                  }}
                </span>
                <span v-else-if="item.reliefAllowance" class="tw-ml-6">
                  <c-text
                    class="tw--mt-6"
                    style="width: 131px"
                    type="number"
                    placeholder="Enter amount"
                    min="0"
                    @input="
                      checkReimbursement({
                        id: item.data.id,
                        value: item.data.reimbursements,
                      })
                    "
                    v-model="item.data.reimbursements"
                  />
                </span>
                <span v-else-if="item.netPay" class="grosspay">
                  {{ convertToCurrency(item.data.netPay) }}
                </span>
              </template>
            </sTable>
          </card>
        </div>
      </div>
    </div>
    <side-modal
      :open-modal="openModal"
      :modal-type="modalType"
      :user-id="userId"
      :pay-item-source="payrunData.type"
      @close="handleClose"
    />
    <edit-side-modal
      :open-modal="openEditModal"
      :modal-type="modalType"
      :user-id="userId"
      :pay-item-source="payrunData.type"
      @close="handleEditClose"
    />
    <Modal v-if="cancelModal">
      <Card class="p-5 flex flex-col max-w-md">
        <div class>
          <p class="font-bold ml-4 my-5 text-center">
            Are you sure you want to cancel this Pay run?
          </p>
          <div class="flex">
            <Button
              :background="appearance.buttonColor || defaultBTNColor"
              class="text-white" @click="cancelPayrun"
            >
              Yes
            </Button>
            <Button
              background-color="none"
              class="text-dynamicBackBtn border border-solid border-dynamicBackBtn ml-4"
              @click="cancelModal = false"
            >
              Cancel
            </Button>
        </div>
        </div>
      </Card>
    </Modal>
    <ViewTotalGross v-if="isTotalGross"
      :total-gross="employeeGrossPay"
       :total-net="totalNetPay"
        :total-org-pension="orgPension"
         :total-paye="paye"
          :total-pension="employeePension"
           :total-nhf="nhfAmount"
      @close="handleCloseTotalGross"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import lodash from "lodash/cloneDeep";
import BackButton from "@scelloo/cloudenly-ui/src/components/back-button";
import Breadcrumb from "@scelloo/cloudenly-ui/src/components/bread-crumb";
import CText from "@scelloo/cloudenly-ui/src/components/text";
import STable from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Button from "@/components/Button";
import Menu from "@/components/Menu";
import Modal from "@/components/Modal";

export default {
  components: {
    Modal,
    BackButton,
    Breadcrumb,
    Card,
    CText,
    Menu,
    Button,
    STable,
    SideModal: () => import("./SideModal"),
    EditSideModal: () => import("./EditSideModal"),
    TrendState: () => import("@/components/TrendState"),
    ViewTotalGross: () => import("@/components/ViewTotalGross"),
    TrendToolTip: () => import("@/components/TrendToolTip"),
  },
  data() {
    return {
      type: "",
      isTrendToolTip: false,
      isTrend: false,
      isTotalGross: false,
      payDate: "",
      payrunData: {},
      payrunTrend: null,
      employeeGrossPay: 0,
      growthTrend: null,
      orgPension: 0,
      paye: 0,
      employeePension: 0,
      nhfAmount: 0,
      initiatedBy: "",
      selectedEmployees: 0,
      currentPayrunData:{},
      trendEmployees: 0,
      cancelModal: false,
      payment: {},
      processAllEmployees: false,
      disableBtn: false,
      metaData: {},
      itemsPerPage: 10,
      numberOfPage: 1,
      tableLoading: false,
      hierarchy: [],
      openModal: false,
      openEditModal: false,
      payrunTrend: null,
      modalType: "",
      userId: "",
      isPresent: true,
      payrunType: "",
      categoryArray: [
        {
          name: "Regular",
          value: "Regular",
          radioName: "job",
        },
        {
          name: "Off Cycle",
          value: "Off Cycle",
          radioName: "job",
        },
        {
          name: "Terminal Pay",
          value: "Terminal Pay",
          radioName: "job",
        },
      ],
      additionalItems: [],
      month: [],
      year: [],
      employeeAddition: [],
      amount: "",
      payCycleMonth: "",
      payCycleYear: "",
      reimbursement: "",
      variableMonth: "",
      employeeSearch: false,
      advancedSearch: false,
      netPay: [],
      payType: "",
      payFrequency: "",
      splitPosition: "",
      totalEmployees: 0,
      totalNetPay: 0,
      optimaUsers: [],
      paycycleSummary: [],
      employees: {},
      radioValue: "",
      allButton: "all",
      checkboxes: [],
      selected: [],
      allOptimaUsers: "",
      AVMonth: "",
      loading: true,
      tableData: [],
      breadcrumbs: [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll",
        },
        {
          disabled: false,
          text: "Payrun",
          href: "payrun",
          id: "Payrun",
        },
        {
          disabled: false,
          text: `Pay Cycle`,
          id: "Advance Request",
        },
      ],
      item: [],
      setModal: false,
      regularBackPayheaders: [
        { title: "", value: "photo" },
        { title: "Employee Name", value: "fname" },
        { title: "Regular Earnings", value: "regularEarnings" },
        { title: "Additions", value: "additions" },
        { title: "Deductions", value: "deductions" },
        { title: "PAYE", value: "paye" },
        { title: "Pension", value: "employeePension" },
        { title: "NHF", value: "nhfAmount" },
        { title: "Net Pay", value: "netPay", width: 10 },
      ],
      offCyclesheaders: [
        { title: "", value: "photo"},
        { title: "Employee", value: "fname" },
        { title: "Payment Due", value: "regularEarnings" },
        { title: "Additions", value: "additions" },
        { title: "Deductions", value: "deductions" },
        { title: "PAYE", value: "paye" },
        { title: "Net Pay", value: "netPay" },
      ],
      page: 1,
      pageCount: 0,
      runByRadioButton: [
        { name: "All", id: "all" },
        { name: "Location", id: "location" },
        { name: "Function", id: "function" },
        { name: "Employment Type", id: "employmentType" },
        { name: "Level", id: "level" },
      ],
      employmentType: [
        { name: "Full-time", id: "Full-time" },
        { name: "Part-time", id: "Part-time" },
        { name: "Intern", id: "Intern" },
        { name: "Company Temp", id: "Company-paid Temp" },
        { name: "Contractor", id: "Independent Contractor" },
        { name: "Agency Temp", id: "Agency-paid Temp" },
        { name: "Vendor Employee", id: "Vendor Employee" },
        { name: "Volunteer", id: "Volunteer" },
      ],
      runByCheckbox: [],

      paymentType: [
        { name: "Regular Pay", id: "regularPay" },
        { name: "13 Month", id: "13thMonth" },
      ],
      selectedPaymentType: "13thMonth",
      drawerVisible: false,
      selitems: [],
      locationArray: [],
      functionArray: [],
      employmentArray: [],
      levelArray: [],
      secondaryCategory: "",
      secondaryCategoryArray: "",
    };
  },
  methods: {
    handleRowSelected(value) {
      // Assign selected rows and reset values
      this.checkboxes = value;
      this.selectedEmployees = value.length;
      this.trendEmployees = value.length

      // Use reduce to calculate totalNetPay and employeeGrossPay
      const total =
        this.checkboxes.reduce((acc, element) => {
        if (element.netPay) {
          acc.totalNetPay += element.netPay;
          acc.employeeGrossPay += element.grossEarnings;
          acc.orgPension += element.orgPension;
          acc.paye += element.paye;
          acc.employeePension += element.employeePension;
          acc.nhfAmount += element.nhfAmount;
        }
        return acc;
      }, { totalNetPay: 0, employeeGrossPay: 0, orgPension: 0, paye: 0, employeePension: 0, nhfAmount: 0 });


      this.totalNetPay = total.totalNetPay;
      this.employeeGrossPay = total.employeeGrossPay;
      this.orgPension = total.orgPension;
      this.paye = total.paye;
      this.employeePension = total.employeePension;
      this.nhfAmount = total.nhfAmount;

      if (this.currentPayrunData?.grossPay && this.currentPayrunData?.netPay && this.currentPayrunData?.empCount) {
        total.totalNetPay += this.currentPayrunData.netPay;
        total.employeeGrossPay += this.currentPayrunData.grossPay;
        this.trendEmployees += this.currentPayrunData.empCount
      }
      // Update current payrun data
      this.payrunTrend.currentPayrun.grossPay = total.employeeGrossPay;
      this.payrunTrend.currentPayrun.netPay = total.totalNetPay;
      this.payrunTrend.currentPayrun.empCount = this.trendEmployees;

      // Calculate variance for grossPay, netPay, and empCount
      const { previousPayrun, currentPayrun, variance } = this.payrunTrend;
      variance.grossPay = currentPayrun.grossPay - previousPayrun.grossPay;
      variance.netPay = currentPayrun.netPay - previousPayrun.netPay;
      variance.empCount = currentPayrun.empCount - previousPayrun.empCount;

      // Determine trend direction for each field
      this.growthTrend = this.calculateTrend(variance);

    },

    // Helper function to calculate trend direction
    calculateTrend(variance) {
      return {
        grossPay: variance.grossPay > 0 ? 'upward' : variance.grossPay < 0 ? 'downward' : 'no change',
        netPay: variance.netPay > 0 ? 'upward' : variance.netPay < 0 ? 'downward' : 'no change',
        empCount: variance.empCount > 0 ? 'upward' : variance.empCount < 0 ? 'downward' : 'no change'
      };
    },

    handleCloseTotalGross(){
      this.isTotalGross = false
    },
    handleClose() {
      this.getOnePayrun('?page=1&perPage=50');
      this.openModal = false;
      this.modalType = "";
    },
    handleEditClose() {
      this.getOnePayrun('?page=1&perPage=50');
      this.openEditModal = false;
      this.modalType = "";
    },
    async handleEdit(type, id) {
      try {
        await this.$handlePrivilege("payRun", "editPayRunOnDraft");
        this.modalType = type;
        this.userId = id;
        this.openEditModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    async handleClick(type, id) {
      try {
        await this.$handlePrivilege("payRun", "editPayRunOnDraft");
        this.modalType = type;
        this.userId = id;
        this.openModal = true;
      } catch (error) {
        this.$toasted.error("You do not have permission to perform this task", {
          duration: 5000,
        });
      }
    },
    handleProcessAllEmployees() {},
    handleItemsPerPage(itemNo) {
      this.itemsPerPage = itemNo;
      this.queryUpdate();
    },
    handlePage(pageNumber) {
      this.numberOfPage = pageNumber;
      this.queryUpdate();
    },
    queryUpdate() {
      const pageNumber = this.numberOfPage
        ? `?page=${this.numberOfPage}`
        : `?page=${this.metaData.page}`;
      const itemPage = this.itemsPerPage ? `&perPage=${this.itemsPerPage}` : "";
      this.getOnePayrun(`${pageNumber}${itemPage}`);
    },
    async buttonOptionSelected(option) {
      if (option === "Save as Draft") {
        this.save("Drafts");
      } else {
        this.cancelModal = true
      }
    },
    async cancelPayrun() {
      this.cancelModal = false
      try{
        await this.$_deletePayrun(this.$route.params.id)
        this.$toasted.success(`Pay run cancelled successfully`, {duration:5000})
        this.$router.push({ name: "Pay Runs" });
      }
      catch {
        this.$toasted.error("Pay run cannot be cancelled, please try again", {duration: 5000})
      }
    },
    handleModal() {
      this.setModal = true;
    },
    invalid_permission() {
      this.$store.commit("SET_TOAST", {
        text: "You Do not have the required permission",
        color: "error",
      });
    },

    save(value) {
      this.disableBtn = true;
      if (this.checkboxes.length) {
        const getPayrun = {
          employees: [],
          payrun: {},
          type: "regular"
        };
        getPayrun.payrun.id = this.$route.params.id;

        this.checkboxes.forEach((element) => {
          if (element.reimbursements) {
            parseFloat(element.reimbursements);
          }
          getPayrun.employees.push(element.id);
        });

        if (value === "Drafts") {
          getPayrun.payrun.payrollStatus = "draft";
          getPayrun.payrun.payrunNo = this.payrunData.payrunNo;
        } else {
          getPayrun.payrun.payrollStatus = "review";
        }
        this.$_editOnePayrun(getPayrun)
          .then((result) => {
            this.disableBtn = false;
            this.$toasted.success("Pay run updated successfully", {
              duration: 5000,
            });
            this.$router.push({
              name: "Pay Runs",
              query: { currentTab: value },
            });
            return result;
          })
          .catch((err) => {
            this.disableBtn = false;
            this.$toasted.error(
              "An error occured, Please contact an IT personnel",
              { duration: 5000 }
            );
            return err;
          });
      } else {
        this.$toasted.error(
          "No employee selected, Please select an employee to pay",
          { duration: 3000 }
        );
        this.disableBtn = false;
      }
    },
    checkReimbursement(value) {
      const { id } = value;
      let amount = parseFloat(value.value);
      const hasNetPay = this.netPay.find((element) => element.id === id);
      if (hasNetPay) {
        const data = this.tableData.find((item) => item.id === id);
        if (!amount) amount = 0;
        const initialNetPayObject = lodash(hasNetPay);

        initialNetPayObject.netPay += amount;
        data.netPay = initialNetPayObject.netPay;
      }
    },
    getOnePayrun(params) {
      this.$_getOnePayrun(this.$route.params.id, params).then((result) => {
        const { employees, ...payrun } = result.data.payrun;
        this.payment = result.data.payment;
        this.payrunData = payrun;
        this.metaData = result.data.payrun.meta;
        this.payrunTrend = result.data.payrun.payrunTrend;
        const {currentPayrun} = this.payrunTrend
        if(currentPayrun.grossPay > 0 && currentPayrun.netPay > 0 && currentPayrun.empCount > 0){
          this.currentPayrunData.grossPay = currentPayrun.grossPay
          this.currentPayrunData.netPay = currentPayrun.netPay
          this.currentPayrunData.empCount = currentPayrun.empCount
        }

        this.tableData = employees.map((emp) => ({
          ...emp,
          paye: emp.monthlyPAYE,
          status: payrun.payrollStatus,
        }));

        if (employees) {
          employees.forEach((val) => {
            this.netPaySum += val.netPay;
          });
        }
        // employees.forEach((item) => {
        //   this.totalNetPay += item.netPay;
        // });
        this.totalEmployees = employees.length;
        this.payCycleMonth = result.data.payrun.month;
        this.payCycleYear = result.data.payrun.year;
      });
      this.loading = false;
      this.tableLoading = false;
    },
  },
  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
    employeeTotalCount(){
      const count = this.currentPayrunData?.empCount || 0
      return this.totalEmployees + count
    },
    headers(){
      if(this.payrunData.type && this.payrunData.type === 'off-cycle'){
        return this.offCyclesheaders
      }

      return this.regularBackPayheaders
    },
    breadcrumbsOffCycle(){
      return [
        {
          disabled: false,
          text: "Payroll",
          href: "payroll",
          id: "Payroll",
        },
        {
          disabled: false,
          text: "Payrun",
          href: "payrun",
          id: "Payrun",
        },
        {
          disabled: false,
          text: `Off-Cycle Pay`,
          href: "OffCyclePay",
          id: "OffCyclePay",
        },
        {
          disabled: false,
          text: `${this.payrunData.payType}`,
          href: `${this.payrunData.payType}`,
          id: `${this.payrunData.payType}`,
        },
        {
          disabled: false,
          text: `Draft`,
          id: `Draft`,
        },
      ];
    }
  },
  beforeMount() {
    this.getOnePayrun('?page=1&perPage=50');
  },
};
</script>

<style scoped>
.buttons {
  width: 126px;
  height: 44px;
  border-radius: 5px;
}
.sideDot1 {
  width: 7px;
  height: 7px;
  background-color: #46aa80;
}
.sideDot2 {
  width: 7px;
  height: 7px;
  background-color: #c3c2c4;
}
.grosspay {
  background: #2176ff26;
  padding: 0.3rem 0.7rem;
  color: #2176ff;
  border-radius: 5px;
}

.w-204{
  flex: 1 1 calc(100% / 7) !important;
}
.gridContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(450px, 1fr)); /* Responsive columns */
    gap: 1rem; /* Gap between the grid items */
}
.text-10{
  font-size: 10px !important
}
</style>
